<template>
  <div class="home"></div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
body {
  background: #111111;
}
.home {
  background: #111111;
  height: 100vh;
  width: 100%;
}
* {
  margin: 0;
  padding: 0;
}
</style>
